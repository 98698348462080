/* eslint-disable react-hooks/exhaustive-deps */
import styles from './Layout.module.scss'
import React, { useEffect, useState } from 'react'
import { GlobalHeader } from '@climatetrade/ui'
import { i18n } from 'next-i18next'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
  setCurrencies,
  setCurrencyCode,
  setCurrencySymbol
} from 'src/features/compensation/compensationSlice'
import { useRouter } from 'next/router'
import Cookie from 'js-cookie'
import { fetchCart, fetchCartNoLogued, getProjects } from 'src/features/projects/projectsAPI'
import {
  setCart,
  setCartLength,
  setProject,
  setReloadProjects,
} from 'src/features/projects/projectSlice'
import {
  fetchUser,
  validToken,
  refreshToken
} from 'src/features/users/usersAPI'
import {
  selectUser,
  setIsUserLogued,
  setUser
} from 'src/features/users/userSlice'
import { currencyConverter } from 'src/features/compensation/compensationAPI'
import FooterContainer from '../FooterContainer/FooterContainer'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { ls_currencycode_name } from 'src/static/models/localstorage.names'
import {
  setLoading,
  setTrackHome,
  setTrackResult,
  setFiltersKeys
} from 'src/features/ui/uiSlice'
import {
  getLanguageCookie,
  getCurrencyCookie,
  checkHideWelcomeBackCookie,
  checkLoggedInCookie,
  setHideWelcomeBackCookie
} from 'src/helpers/cookies.helpers'
import { expiresInSeconds } from 'src/helpers/utils'
import WelcomeBackModal from '../WelcomeBackModal/WelcomeBackModal'
import * as Sentry from '@sentry/nextjs'
import { setOrder } from 'src/features/filters/filtersSlice'
import Cookies from 'js-cookie'



interface Props {
  children: React.ReactNode;
  headerData: any;
}

const Layout: React.FC<Props> = ({ children, ...props }) => {
  const { headerData } = props

  const user = useAppSelector(selectUser)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const router = useRouter()
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.ui.loading)
  const trackHome = useAppSelector((state) => state.ui.trackHome)
  const trackResult = useAppSelector((state) => state.ui.trackResult)
  const isUserLogued = useAppSelector((state) => state.user.isUserLogued)
  const updateUser = useAppSelector((state) => state.user.updateUser)
  const currencies = useAppSelector((state) => state.compensation.currencies)
  const order = useAppSelector((state) => state.filters.order)
  const currencyCode = useAppSelector((state) => state.compensation.currency_code)

  const tokenName = process.env.TOKEN_NAME
  const languageCodeEnv = process.env.LANGUAGE_CODE
  const currencyCodeEnv = process.env.CURRENCY_CODE
  const route = router.route

  const languageCookie = getLanguageCookie()

  useEffect(() => {

    i18n.changeLanguage(languageCookie)
    router.push(router.asPath, undefined, {
      locale: languageCookie, scroll: false
    })
  }, [languageCookie])


  const handleCurrency = (currencies, selectedCurrencyCode) => {
    const currencyUser = currencies.find(
      (el) => el.code === selectedCurrencyCode
    )
    localStorage.setItem(ls_currencycode_name, selectedCurrencyCode)
    dispatch(setCurrencySymbol(currencyUser?.symbol))
    dispatch(setCurrencyCode(currencyUser?.code))
  }

  const handleUserNotLogued = () => {
    let currencies
    let selectedCurrencyCode = currencyCodeEnv
    let selectedLanguageCode = languageCodeEnv

    currencyConverter()
      .then((currencyResponse) => {
        currencies = currencyResponse
        dispatch(setCurrencies(currencyResponse))
        return fetchCartNoLogued()
      })
      .then((res) => {
        dispatch(setCart(res))
        let cartLength = 0
        res.forEach((pr) => {
          if (pr.is_vintage) {
            cartLength += pr.vintage_items_orders.length
          } else {
            cartLength += 1
          }
        })
        dispatch(setCartLength(cartLength))

        const currencyCodeStorage = getCurrencyCookie()

        if (currencyCodeStorage) {
          selectedCurrencyCode = currencyCodeStorage
        }
        handleCurrency(currencies, selectedCurrencyCode)

        const languageCodeStorage = getLanguageCookie()
        if (languageCodeStorage) {
          selectedLanguageCode = languageCodeStorage
        }
        dispatch(setIsUserLogued(false))
        dispatch(setLoading(true))
        i18n.changeLanguage(selectedLanguageCode)
        router.push(router.asPath, undefined, {
          locale: selectedLanguageCode, scroll: false
        })
        checkLoginCookie()
      })
      .catch((e) => console.error(e))
  }

  const handleUserLogued = () => {
    let selectedCurrencyCode = currencyCodeEnv
    let currencies

    fetchCart().then((res) => {
      dispatch(setCart(res))
      let cartLength = 0
      res.forEach((pr) => {
        if (pr.is_vintage) {
          cartLength += pr.vintage_items_orders.length
        } else {
          cartLength += 1
        }
      })
      dispatch(setCartLength(cartLength))
    })
    currencyConverter()
      .then((currencyResponse) => {
        currencies = currencyResponse
        dispatch(setCurrencies(currencyResponse))
        return fetchUser()
      })
      .then((userResponse) => {
        dispatch(setUser(userResponse))

        if (userResponse.currency_token_code) {
          selectedCurrencyCode = userResponse.currency_token_code
        }
        handleCurrency(currencies, selectedCurrencyCode)

        dispatch(setIsUserLogued(true))
        dispatch(setLoading(true))
        i18n.changeLanguage(userResponse?.language)
        router.push(router.asPath, undefined, {
          locale: userResponse?.language, scroll: false
        })
      })
      .catch((e) => console.error(e))
  }

  const handleCleanCredentials = () => {
    localStorage.removeItem(process.env.TOKEN_NAME)
    Cookie.remove(process.env.TOKEN_NAME, {
      domain: process.env.CT_DOMAIN,
      path: '/'
    })
  }

  const newToken = (sToken) => {
    refreshToken(sToken)
      .then((response) => {
        Cookie.remove(process.env.TOKEN_NAME, {
          domain: process.env.CT_DOMAIN,
          path: '/'
        })

        Cookie.set(process.env.TOKEN_NAME, response.token, {
          domain: process.env.CT_DOMAIN,
          path: '/',
          expires: new Date(Date.now() + 3600000)
        })

        handleUserLogued()
      })
      .catch(() => {
        handleCleanCredentials()
        handleUserNotLogued()
      })
  }

  useEffect(() => {
    const sToken = Cookie.get(tokenName)
    if (sToken) {
      validToken(sToken)
        .then((responseToken) => {
          if (sToken == responseToken.token) {
            if (expiresInSeconds(sToken, 600)) {
              newToken(sToken)
            } else {
              handleUserLogued()
            }
          }
        })
        .catch(() => {
          handleCleanCredentials()
          handleUserNotLogued()
        })
    } else {
      handleUserNotLogued()
    }
  }, [])

  useEffect(() => {
    if (loading) {
      dispatch(checkCookies())
      switch (route) {
        case '/':
          if (!trackHome) {
            dispatch(trackEvent('MARKET_HOME', 'PAGE_VIEW'))
            dispatch(setTrackHome(true))
          }
          break
        case '/results':
          if (!trackResult) {
            dispatch(trackEvent('MARKET_SEARCH', 'PAGE_VIEW'))
            dispatch(setTrackResult(true))
          }
          break
      }
    }
  }, [route, loading])

  useEffect(() => {
    if (user) Sentry.setUser({ email: user?.email })
  }, [user])

  const checkLoginCookie = () => {
    setIsModalVisible(!checkHideWelcomeBackCookie() && checkLoggedInCookie() && !isUserLogued)
  }

  const handleLanguageChange = (lang: string) => {
    if (i18n.language != lang) {
      i18n.changeLanguage(lang)
      router.push(router.asPath, undefined, { locale: lang, scroll: false })
    }

  }

  const handleCurrencyChange = (selectedCurrencyCode) => {
    if (currencyCode != selectedCurrencyCode) {
      const currencyUser = currencies.find((el) => el.code === selectedCurrencyCode)
      const profileCookie = Cookies.get('ct-profile')

      dispatch(setReloadProjects(true))
      dispatch(setCurrencySymbol(currencyUser?.symbol))
      dispatch(setCurrencyCode(currencyUser?.code))
      getProjects(order, profileCookie).then((project) => {
        dispatch(setOrder(order))
        dispatch(setProject(project))
        dispatch(setReloadProjects(false))
      })
      dispatch(setFiltersKeys(['projectKind']))
    }
  }

  return (
    <div data-testid='layout-test'>
      <main className={styles.content}>
        <div style={{ position: 'absolute', top: '0px', width: '100%', background: 'white' }}>
          <GlobalHeader
            data={headerData}
            onLanguageChange={(lang) => handleLanguageChange(lang)}
            onCurrencyChange={(currency) => handleCurrencyChange(currency)}
          />
        </div>

        <div style={{ position: 'absolute', top: '60px' }}>
          {children}
          <div style={{
            textAlign: 'left',
            marginTop: '1.5rem',
            width: '100vw',
          }}>
            <FooterContainer />
          </div>
        </div>
      </main>

      <WelcomeBackModal
        isModalVisible={isModalVisible}
        handleCancel={() => {
          setIsModalVisible(false)
          setHideWelcomeBackCookie()
        }
        }
      />
    </div>
  )
}

export default Layout
